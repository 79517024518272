.category-checkbox {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  cursor: pointer;
  border-radius: 5px;
  user-select: none;
  transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  color: #fff;
  opacity: 0.9;
}

.center-button {
  width: 35px;
  display: block;
  align-items: center;
  justify-content: center;
  height:35px;
  background:rgba(150, 150, 150, 0.4);
  border:none;
  cursor:pointer;
}

.center-button-icon {
  margin-left: -4px;
  width: 31px;
  display:flex;
  align-items: center;
  justify-content: center; 
  height: 31px; 
  background: white;
}

@media (hover: hover) {
  .category-checkbox:hover {
    opacity: 1;
    transform: scale(1.05);
  }
}